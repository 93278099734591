export const Spacer = ({ w = undefined, h = undefined }) => (
  <div
    style={{
      ...(w
        ? {
            marginRight: `${w}px`,
          }
        : {}),
      ...(h
        ? {
            marginBottom: `${h}px`,
          }
        : {}),
    }}
  ></div>
);
